import { render, staticRenderFns } from "./imgs.vue?vue&type=template&id=f577d0fc&scoped=true"
import script from "./imgs.vue?vue&type=script&lang=js"
export * from "./imgs.vue?vue&type=script&lang=js"
import style0 from "./imgs.vue?vue&type=style&index=0&id=f577d0fc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f577d0fc",
  null
  
)

export default component.exports